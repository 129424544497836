import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import ClickTone from 'clicktone';

const winMP3 = new URL('../assets/audio/win.mp3', import.meta.url);
const unlockMP3 = new URL('../assets/audio/unlock.mp3', import.meta.url);

const winSound = new ClickTone({
  file: winMP3,
  volume: 0.7,
});

const unlockSound = new ClickTone({
  file: unlockMP3,
  volume: 0.7,
});

gsap.registerPlugin(CustomEase);

class Chest {
  constructor() {
    this.chest = gsap.utils.toArray('.chest');
    this.button = document.querySelector('.btn-pick');
    this.modal = document.querySelector('.modal');
    this.tlChestSwing = gsap.timeline({ paused: true });
    this.tlChestStop = gsap.timeline({ paused: true });
    this.tlChestSwim = gsap.timeline({ paused: true });
    this.tlButton = gsap.timeline({ paused: true });
    this.tlChestShadow = gsap.timeline();
    this.tlChestPick = gsap.timeline();
  }

  courseAnimation() {
    this.tlChestSwing
      .to(this.chest, {
        duration: 7,
        rotate: -7,
        ease: CustomEase.create('custom', 'M0,0 C0.046,0 0.047,-0.2 0.1,-0.2 0.149,-0.2 0.126,0.618 0.193,0.618 0.258,0.618 0.276,-0.729 0.344,-0.729 0.415,-0.729 0.428,0.749 0.496,0.749 0.557,0.749 0.574,-0.748 0.65,-0.748 0.716,-0.748 0.741,0.626 0.8,0.626 0.86,0.626 0.852,-0.2 0.9,-0.2 0.95,-0.2 0.942,0 1,0'),
        yoyo: true,
        repeat: -1,
      });

    this.tlChestSwim
      .to(this.chest, {
        ease: 'power1.inOut',
        x: 'random(-7, 7)',
        y: 'random(-7, 7)',
        duration: 2,
        repeat: -1,
        yoyo: true,
        repeatRefresh: true,
      });

    this.tlChestStop
      .to(this.chest, {
        rotate: 0,
        duration: 0.5,
        pointerEvents: 'none',
      });

    this.tlButton
      .set(this.button, {
        y: 40,
      })
      .to(this.button, {
        autoAlpha: 1,
        duration: 0.65,
        y: 3,
        ease: 'back.out(4)',
      })
      .fromTo(this.button, {
        y: 3,
      }, {
        y: -3,
        duration: 2,
        yoyo: true,
        repeat: -1,
        ease: 'power1.inOut',
      });

    this.tlChestSwing.play();
  }

  pickAnimation(clickedChest) {
    this.tlChestPick
      .fromTo(clickedChest, {
        x: -7,
      }, {
        x: 7,
        repeat: 20,
        duration: 0.03,
        yoyo: true,
        onStart: () => unlockSound.play().then(),
      })
      .to(clickedChest, {
        y: -30,
        duration: 0.25,
        ease: 'circ.out',
        onStart: () => {
          setTimeout(() => {
            clickedChest.classList.add('win');

            winSound.play().then();

            this.chest.forEach((i) => {
              if (i !== clickedChest) {
                i.classList.add('loose');
              }
            });
          }, 40);
        },
      }, '-=0.05')
      .to(clickedChest, {
        y: 0,
        duration: 0.25,
        ease: 'circ.in',
        onComplete: () => {
          this.tlChestSwim.play();

          setTimeout(() => {
            this.tlButton.play();
          }, 150);
        },
      })
      .set(clickedChest, {
        '--shadow-color': '0',
      })
      .to(clickedChest, {
        duration: 5,
        '--shadow-color': '360',
        ease: 'none',
        repeat: -1,
      });

    return this.tlChestPick;
  }

  handle(event) {
    const clickedChest = event.target.closest('.chest');

    if (clickedChest) {
      this.tlChestSwing.pause();
      this.tlChestStop.play();
      this.pickAnimation(clickedChest).play();
    }
  }

  init() {
    this.courseAnimation();

    document.addEventListener('pointerdown', this.handle.bind(this));
  }
}

export default Chest;
